import React from "react"
import Index from "@pages/pamm-system.js";

const PammsystemJa = () => {
  const setLang = 'ja';
  return (
  <><Index langType={setLang}/></>
  )
}
export default PammsystemJa
